<template>
    <v-app>
        <v-app-bar
            v-if="$route.name !== 'Login' && $route.name !== 'CriiptoRedirectCallback'"
            app
            dense
        >

            <v-spacer />
            <v-btn                
                link
                small
                :to="{ name: 'Login' }"
            >
                {{ $t('loginPage-login') }}
            </v-btn>
        </v-app-bar>
        <v-main>
            <router-view />
        </v-main>
        <footer-bar />
    </v-app>
</template>

<script>
import FooterBar from '@/views/shared/Footer'

export default {
    components: {
        FooterBar
    },
    data() {
        return {
        }
    },
    computed: {

    },
    methods: {
    }
}
</script>